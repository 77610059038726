import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Page from './Page';
const LandingPage = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col md:px-8 sm:px-4 bg-white dark:bg-gray-800 bg-landing bg-center bg-cover flex-1 rounded shadow items-center justify-center antialiased pb-4"
    >
      <motion.div
        className="cta flex flex-col items-start my-8 bg-white rounded bg-opacity-95 p-10 dark:bg-gray-600 dark:text-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <h1 className="title text-4xl">Welcome to Bi(bli)oArch!</h1>
        <h2 className="subtitle text-2xl">
          Bibliographic database for human bioarchaeological studies
        </h2>
        <h2 className="subtitle text-2xl">
          in the Eastern Mediterranean and Middle East.
        </h2>
        <Link
          to="/data"
          className="self-end px-8 py-4 mt-6 rounded text-white text-xl bg-gradient-to-tr from-blue-700 to-blue-900 transition-all transform hover:-translate-y-1 hover:scale-110"
        >
          Get Started
        </Link>
      </motion.div>
      <section className="description text-justify flex bg-white rounded bg-opacity-95 p-10 dark:bg-gray-600 dark:text-white">
        <div className="flex-1">
          <h3 className="text-4xl">What is Bi(bli)oArch?</h3>
          <Page />
        </div>
      </section>
    </motion.div>
  );
};

export default LandingPage;
