import { useState } from 'react';
import { motion } from 'framer-motion';

const Page = ({ index }) => {
  const [pageNo, setPageNo] = useState(0);
  const pages = [
    <p className="prose prose-lg dark:text-white">
      Bi(bli)oArch is a bibliographic database for human bioarchaeological
      studies in the Eastern Mediterranean and Middle East (EMME),
      chronologically covering skeletal assemblages from prehistory to early
      modern times. The database specifically contains published works on human
      skeletal remains; however, it does not include papers on mortuary/funerary
      archaeology where there is no explicit presentation of human skeletal
      data. This database emerged from an increasing realization that many
      studies on human skeletal remains in the region have been published in
      national archaeology journals, excavation monographs, ‘grey’ reports, or
      as graduate theses, making them difficult to find, especially by
      international scholars. A further complicating factor is that many
      relevant studies have been published in the national languages of the
      region, hence they do not appear during any basic online search in
      English. Bi(bli)oArch aims at overcoming these limitations and promoting
      bioarchaeological research in the EMME by making human bioarchaeology
      papers and reports more easily discoverable.
    </p>,
    <p className="prose prose-lg dark:text-white">
      The database allows search by region and theme. The regional search adopts
      modern-day national borders, while acknowledging that these borders had
      little to no meaning in the past. For simplicity, the term Levant has been
      adopted to jointly denote countries along the Levantine coast and Jordan,
      while Arabian Peninsula has been used to capture Bahrain, Kuwait, Oman,
      Qatar, Saudi Arabia, the United Arab Emirates, and Yemen. The thematic
      division of the entries uses the following broad bioarchaeological themes:
      <ul>
        <li>Activity</li>
        <li>Ancient DNA</li>
        <li>Biodistance</li>
        <li>Demography</li>
        <li>Diet</li>
        <li>Isotopes</li>
        <li>Metrics</li>
        <li>Mobility</li>
        <li>No Keyword</li>
        <li>Nonmetrics</li>
        <li>Pathology</li>
        <li>Stature</li>
        <li>Taphonomy</li>
      </ul>
    </p>,
    <p className="prose prose-lg dark:text-white">
      If no search terms are input, all available titles will be given in the
      output list. Even though every effort has been made to access all works
      listed in the database, in certain cases this was not possible. In such
      cases, the papers have been tagged as ‘No Region’ or ‘No Keyword’ unless
      the region and/or theme were clear from the publication title. The
      Bi(bli)oArch database currently contains 3,521 titles and will be
      continuously updated. We would be deeply grateful if you could contact us
      in order to bring to our attention corrections and omissions so that
      Bi(bli)oArch evolves into a cooperative initiative. To stress this
      communal philosophy, Bi(bli)oArch is a free tool and all content is
      distributed under the terms of a Creative Commons license (CC BY-NC-SA
      4.0). Development of the Bi(bli)oArch database was supported by the
      European Regional Development Fund and the Republic of Cyprus through the
      Research and Innovation Foundation [grant number: EXCELLENCE/1216/0023],
      as well as the European Union Horizon 2020 [grant number: 811068].
      Additional support from the A.G. Leventis Foundation through the A.G.
      Leventis Chair in Archaeological Sciences at The Cyprus Institute is
      gratefully acknowledged. This work has also been supported by the Dan
      David Prize.
    </p>,
  ];
  return (
    <motion.div layout>
      {pages[pageNo]}
      <div className="flex justify-between font-bold items-center">
        {pageNo !== 0 ? (
          <button
            className="py-2 px-4 border border-black rounded hover:bg-gray-200 transition-colors dark:hover:bg-gray-600 dark:border-white"
            onClick={() => {
              if (pageNo > 0) setPageNo(pageNo - 1);
            }}
          >
            Back
          </button>
        ) : (
          <p></p>
        )}
        {pageNo + 1}
        {pageNo !== pages.length - 1 ? (
          <button
            className="py-2 px-4 border border-black rounded hover:bg-gray-200 transition-colors dark:hover:bg-gray-600 dark:border-white"
            onClick={() => {
              if (pageNo < pages.length - 1) setPageNo(pageNo + 1);
            }}
          >
            Next
          </button>
        ) : (
          <p></p>
        )}
      </div>
    </motion.div>
  );
};
export default Page;
