import { motion } from 'framer-motion';
import Search from './Search';
import Table from './DataTable';
import { DataContextProvider } from './DataContext';

const DataView = () => {
  return (
    <DataContextProvider>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: 'spring', stiffness: 260, damping: 20 }}
        className="data flex flex-col flex-1"
      >
        <Search />

        <motion.div
          className="flex flex-col items-center justify-center"
          layout
        >
          <Table />
        </motion.div>
      </motion.div>
    </DataContextProvider>
  );
};

export default DataView;
