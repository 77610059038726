import { useState, createContext, useContext, useEffect } from 'react';
import { AppContext } from '../../partials/AppContext.jsx';

export const DataContext = createContext();

export const DataContextProvider = ({ children }) => {
  const { loadData, showSnackBar, setIsLoading, isLoading } = useContext(
    AppContext
  );

  const [count, setCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [results, setResults] = useState([]);
  const [exports, setExports] = useState([]);
  const [sortKey, setSortKey] = useState('-');
  const [searchParameters, setSearchParameters] = useState({
    yearFrom: '',
    yearTo: '',
    countries: [],
    keywords: [],
  });

  useEffect(() => {
    search(pageIndex, pageSize);
    // eslint-disable-next-line
  }, [sortKey, pageIndex, pageSize]);

  const goToPage = (page) => {
    if (page < count && page > -1) {
      setPageIndex(page);
    }
  };
  const resizeTable = (event) => {
    setPageSize(event.target.value);
    setPageIndex(0);
  };

  const addToExports = (paper) => {
    if (exports.filter((elm) => elm.title === paper.title).length !== 0) return;
    if (exports.includes(paper)) return;
    setExports([...exports, paper]);
  };

  const removeFromExports = (paper) => {
    setExports(exports.filter((elm) => paper._id !== elm._id));
  };

  const isInExports = (paper) => {
    for (let exp of exports) {
      if (exp && exp._id === paper._id) return true;
    }
    return false;
  };

  const clearExports = () => {
    setExports([]);
  };

  const search = (pageIndex = 0, pageSize = 10) => {
    loadData(
      '/get/papers.js',
      {
        yearFrom: searchParameters.yearFrom,
        yearTo: searchParameters.yearTo,
        countries: searchParameters.countries.map((e) => e.value),
        keywords: searchParameters.keywords.map((e) => e.value),
        page: pageIndex,
        limit: pageSize,
        type: 'many',
        sort: { [sortKey]: 1 },
        noSort: sortKey.includes('-') ? true : false,
      },
      {
        method: 'POST',
      }
    ).then((response) => {
      const { papers, count } = response;
      setResults(papers);
      setCount(Math.ceil(count / pageSize));
      if (count === 0) showSnackBar('No papers found!');
    });
  };

  const addAll = () => {
    loadData(
      '/get/papers.js',
      {
        yearFrom: searchParameters.yearFrom,
        yearTo: searchParameters.yearTo,
        countries: searchParameters.countries.map((e) => e.value),
        keywords: searchParameters.keywords.map((e) => e.value),
        page: 0,
        limit: 100000,
        type: 'many',
        noSort: true,
      },
      {
        method: 'POST',
      }
    ).then((response) => {
      const { papers } = response;
      setExports(papers);
    });
  };
  // https://ourcodeworld.com/articles/read/189/how-to-create-a-file-and-generate-a-download-with-javascript-in-the-browser-without-a-server
  // https://stackoverflow.com/questions/8847766/how-to-convert-json-to-csv-format-and-store-in-a-variable
  // because need to do quick :p
  const generateTSV = () => {
    setIsLoading(true);
    function download(filename, text) {
      var element = document.createElement('a');
      element.setAttribute(
        'href',
        'data:text/tab-separated-values;charset=utf-8,' +
          encodeURIComponent(text)
      );
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
    if (exports.length === 0) {
      showSnackBar('Cannot export nothing');
      return;
    }
    let fields = Object.keys(exports[0]).filter(
      (col) => col !== 'faulty' && col !== '_id'
    );
    let replacer = function (key, value) {
      return value === null && key !== 'faulty' && key !== '_id' ? '' : value;
    };
    let tsv = exports.map(function (row) {
      return fields
        .map(function (fieldName) {
          return JSON.stringify(row[fieldName], replacer);
        })
        .join('\t');
    });
    tsv.unshift(fields.join('\t')); // add header column
    tsv = tsv.join('\r\n');
    download(`data${Math.trunc(Math.random() * 10000)}.tsv`, tsv);
    setIsLoading(false);
  };

  const generatePDF = (download = true) => {
    if (exports.length === 0) {
      showSnackBar('Cannot export nothing');
      return;
    }
    setIsLoading(true);
    import('../../utils/pdfUtil.js')
      .then((pdfMake) => {
        pdfMake = pdfMake.default;
        const content = [];
        exports.forEach((prePaper) => {
          const paper = {
            title: prePaper.title ? prePaper.title : '',
            year: prePaper.year ? prePaper.year : '',
            volume: prePaper.volume ? prePaper.volume : '',
            doi: prePaper.doi ? prePaper.doi : '',
            authors: prePaper?.authors?.length !== 0 ? prePaper.authors : [],
            countries:
              prePaper?.countries?.length !== 0 ? prePaper.countries : [],
            abstract: prePaper?.abstract,
          };
          content.push(
            `${paper.authors.join(', ')}. ${paper.year}. ${paper.title}. ${
              paper.volume
            }`
          );
          content.push({
            text: paper.doi,
            link: paper.doi,
            color: '#2727EE',
          });
          if (paper.abstract) {
            content.push(' ');
            content.push(paper.abstract);
          }
          content.push(' ');
        });

        const docDefinition = {
          content,
        };
        if (download) pdfMake.createPdf(docDefinition).download();
        else pdfMake.createPdf(docDefinition).open();
        setIsLoading(false);
      })
      .catch((exception) => {
        console.log(exception);
        showSnackBar('Failed to load PDF Utilities. Check console.');
        setIsLoading(false);
      });
  };

  return (
    <DataContext.Provider
      value={{
        results,
        pageIndex,
        pageSize,
        searchParameters,
        count,
        sortKey,
        isLoading,
        setSearchParameters,
        search,
        goToPage,
        resizeTable,
        addToExports,
        removeFromExports,
        isInExports,
        addAll,
        setSortKey,
        generatePDF,
        generateTSV,
        clearExports,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
