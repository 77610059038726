import { motion } from 'framer-motion';
import { useContext, useState } from 'react';
import { AppContext } from '../../partials/AppContext';
import { AdminContext } from './AdminContext';
const AdminDelete = () => {
  const { isLoading, keywords, authors, countries } = useContext(AppContext);
  const {
    requestStatus,
    variants,
    deletePaper,
    deleteComp,
    editParams,
  } = useContext(AdminContext);

  const [compToBeDeleted, setCompToBeDeleted] = useState({
    author: '',
    keyword: '',
    country: '',
  });

  return (
    <div className="delete hover:shadow transition-shadow rounded border-gray-200 border flex flex-col p-4">
      <div className="adminTitle text-3xl font-bold dark:text-gray-200">
        Delete
      </div>
      {editParams.title ? (
        <div className="itemtodelete dark:text-white">
          <p>{editParams?.title}</p>
          <p>{editParams?.year}</p>
          <p>{editParams?.authors?.toString()}</p>
          <p>{editParams?.keywords?.toString()}</p>
          <p>{editParams?.countries?.toString()}</p>
        </div>
      ) : (
        <p className="dark:text-white">
          Please search for an item to delete...
        </p>
      )}

      <motion.button
        className="font-semibold text-white bg-blue-600 py-2 hover:bg-blue-700 active:bg-blue-800 mb-1 text-lg rounded transition-all"
        animate={requestStatus}
        variants={variants}
        onClick={() => deletePaper(editParams._id)}
        disabled={isLoading || !editParams._id}
      >
        {isLoading ? 'Please Wait...' : 'Delete Paper'}
      </motion.button>
      {/* refactor below into component */}
      <div className="flex flex-col">
        <select
          className="py-2 my-2 border border-gray-400"
          onChange={(e) =>
            setCompToBeDeleted({ ...compToBeDeleted, author: e.target.value })
          }
        >
          <option value="">Select Author...</option>
          {authors
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((author) => (
              <option key={author._id} value={author._id}>
                {author.name}
              </option>
            ))}
        </select>

        <motion.button
          className="font-semibold text-white bg-blue-600 py-2 hover:bg-blue-700 active:bg-blue-800 mb-1 text-lg rounded transition-all"
          animate={requestStatus}
          variants={variants}
          onClick={() => deleteComp('author', compToBeDeleted.author)}
          disabled={isLoading}
        >
          {isLoading ? 'Please Wait...' : 'Delete Author'}
        </motion.button>
      </div>
      <div className="flex flex-col">
        <select
          className="py-2 my-2 border border-gray-400"
          onChange={(e) =>
            setCompToBeDeleted({ ...compToBeDeleted, keyword: e.target.value })
          }
        >
          <option value="">Select Keyword...</option>
          {keywords
            .sort((a, b) => (a.keyword > b.keyword ? 1 : -1))
            .sort()
            .map((keyword) => (
              <option key={keyword._id} value={keyword._id}>
                {keyword.keyword}
              </option>
            ))}
        </select>

        <motion.button
          className="font-semibold text-white bg-blue-600 py-2 hover:bg-blue-700 active:bg-blue-800 mb-1 text-lg rounded transition-all"
          animate={requestStatus}
          variants={variants}
          onClick={() => deleteComp('keyword', compToBeDeleted.keyword)}
          disabled={isLoading}
        >
          {isLoading ? 'Please Wait...' : 'Delete Keyword'}
        </motion.button>
      </div>
      <div className="flex flex-col">
        <select
          className="py-2 my-2 border border-gray-400"
          onChange={(e) =>
            setCompToBeDeleted({ ...compToBeDeleted, country: e.target.value })
          }
        >
          <option value="">Select Country...</option>
          {countries
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((country) => (
              <option key={country._id} value={country._id}>
                {country.name}
              </option>
            ))}
        </select>

        <motion.button
          className="font-semibold text-white bg-blue-600 py-2 hover:bg-blue-700 active:bg-blue-800 mb-1 text-lg rounded transition-all"
          animate={requestStatus}
          variants={variants}
          onClick={() => deleteComp('country', compToBeDeleted.country)}
          disabled={isLoading}
        >
          {isLoading ? 'Please Wait...' : 'Delete Country'}
        </motion.button>
      </div>
    </div>
  );
};

export default AdminDelete;
