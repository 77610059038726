import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
    fontWeight: 700,
    overflowY: 'scroll',
    maxHeight: window.innerHeight - 150,
  },
}));

const AbstractModal = ({ open, children, close }) => {
  const classes = useStyles();

  return children ? (
    <div style={{ backgroundColor: '#fff' }}>
      <Modal
        className={classes.modal}
        open={open}
        onClose={() => close()}
        closeAfterTransition
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Fade in={open}>
          <div className={classes.paper} onClick={() => close()}>
            <p>{children}</p>
            <p
              style={{
                color: '#777',
                fontSize: 12,
                fontWeight: 400,
              }}
            >
              Click anywhere to dismiss!
            </p>
          </div>
        </Fade>
      </Modal>
    </div>
  ) : null;
};

export default AbstractModal;
