import { motion } from 'framer-motion';
import { useContext } from 'react';
import { AdminContext } from './AdminContext';

const VerifyOverlay = () => {
  const {
    addAllParsedData,
    removeParsedData,
    setParsedData,
    overlay,
    setOverlay,
    parsedData,
  } = useContext(AdminContext);

  const dump = () => {
    let dump = '';

    parsedData.forEach((elm) => {
      const {
        title,
        year,
        volume,
        doi,
        authors,
        keywords,
        countries,
        abstract,
      } = elm;

      dump +=
        authors.join(', ') + '. ' + year + '. ' + title + '. ' + volume + '. ';

      if (doi.trim() !== '') {
        dump += doi + ' ';
      }
      dump += countries.join(', ') + ' ' + keywords.join(', ') + '\n\n';
      if (abstract) dump += abstract + '\n\n';
    });
    navigator.clipboard.writeText(dump);
    console.log(dump);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={overlay ? { opacity: 1 } : { opacity: 0 }}
      className={
        overlay
          ? 'w-full h-full px-1 flex flex-col flex-1 bg-black bg-opacity-70 active fixed top-0 left-0 transition-all'
          : 'w-0 h-0 px-1 flex flex-col flex-1 bg-black bg-opacity-70 active fixed top-0 left-0 transition-all'
      }
    >
      <div className="papers w-full h-full overflow-y-scroll">
        {parsedData.map((elm, idx) => {
          return (
            <div
              className={
                elm.faulty
                  ? 'paper flex flex-col p-2 bg-yellow-600 border border-gray-400 m-1 rounded'
                  : 'paper flex flex-col p-2 bg-white border border-gray-400 m-1 rounded'
              }
              key={idx}
            >
              {elm.faulty ? (
                <p className="bg-red-900 text-red-300 text-center py-2 block font-bold text-2xl">
                  POSSIBLY FAULTY
                </p>
              ) : null}
              <div className="flex items-center">
                <label className="text-lg font-semibold">Title: </label>
                <input
                  className="p-2 text-lg border border-gray-300 rounded mb-1 flex-1 ml-2"
                  onChange={(e) => {
                    e.persist();
                    setParsedData((prevState) => {
                      const dup = [...prevState];
                      dup[idx].title = e.target.value;
                      return dup;
                    });
                  }}
                  value={elm.title}
                />
              </div>
              <div className="flex items-center">
                <label className="text-lg font-semibold">Year: </label>
                <input
                  className="p-2 text-lg border border-gray-300 rounded mb-1 flex-1 ml-2"
                  type="number"
                  onChange={(e) => {
                    e.persist();
                    setParsedData((prevState) => {
                      const dup = [...prevState];
                      dup[idx].year = e.target.value;
                      return dup;
                    });
                  }}
                  value={elm.year}
                />
              </div>
              <div className="flex items-center">
                <label className="text-lg font-semibold">Journal: </label>
                <input
                  className="p-2 text-lg border border-gray-300 rounded mb-1 flex-1 ml-2"
                  onChange={(e) => {
                    e.persist();
                    setParsedData((prevState) => {
                      const dup = [...prevState];
                      dup[idx].volume = e.target.value;
                      return dup;
                    });
                  }}
                  value={elm.volume}
                />
              </div>
              <div className="flex items-center">
                <label className="text-lg font-semibold">Doi: </label>
                <input
                  className="p-2 text-lg border border-gray-300 rounded mb-1 flex-1 ml-2"
                  onChange={(e) => {
                    e.persist();
                    setParsedData((prevState) => {
                      const dup = [...prevState];
                      dup[idx].doi = e.target.value;
                      return dup;
                    });
                  }}
                  value={elm.doi}
                />
              </div>
              <div className="flex items-center">
                <label className="text-lg font-semibold">Authors: </label>
                <input
                  className="p-2 text-lg border border-gray-300 rounded mb-1 flex-1 ml-2"
                  onChange={(e) => {
                    e.persist();
                    setParsedData((prevState) => {
                      const dup = [...prevState];
                      dup[idx].authors = e.target.value.trim().split(',');
                      return dup;
                    });
                  }}
                  value={elm.authors}
                />
              </div>
              <div className="flex items-center">
                <label className="text-lg font-semibold">Keywords: </label>
                <input
                  className="p-2 text-lg border border-gray-300 rounded mb-1 flex-1 ml-2"
                  onChange={(e) => {
                    e.persist();
                    setParsedData((prevState) => {
                      const dup = [...prevState];
                      dup[idx].keywords = e.target.value.trim().split(',');
                      return dup;
                    });
                  }}
                  value={elm.keywords}
                />
              </div>
              <div className="flex items-center">
                <label className="text-lg font-semibold">Countries: </label>
                <input
                  className="p-2 text-lg border border-gray-300 rounded mb-1 flex-1 ml-2"
                  onChange={(e) => {
                    e.persist();
                    setParsedData((prevState) => {
                      const dup = [...prevState];
                      dup[idx].countries = e.target.value.trim().split(',');
                      return dup;
                    });
                  }}
                  value={elm.countries}
                />
              </div>
              <div className="flex items-center">
                <label className="text-lg font-semibold">Abstract: </label>
                <textarea
                  className="p-2 text-lg border border-gray-300 rounded mb-1 flex-1 ml-2"
                  onChange={(e) => {
                    e.persist();
                    setParsedData((prevState) => {
                      const dup = [...prevState];
                      dup[idx].abstract = e.target.value;
                      return dup;
                    });
                  }}
                  value={elm.abstract}
                />
              </div>
              <button
                className="font-semibold text-white bg-blue-600 py-2 hover:bg-blue-700 active:bg-blue-800 mb-1 text-lg rounded transition-all"
                onClick={() => removeParsedData(idx)}
              >
                Delete
              </button>
            </div>
          );
        })}
      </div>
      <button
        className="font-semibold text-white bg-blue-600 py-2 hover:bg-blue-700 active:bg-blue-800 mb-1 text-lg rounded transition-all"
        onClick={dump}
      >
        Dump to clipboard
      </button>
      <button
        className="font-semibold text-white bg-blue-600 py-2 hover:bg-blue-700 active:bg-blue-800 mb-1 text-lg rounded transition-all"
        onClick={addAllParsedData}
      >
        Add All
      </button>
      <button
        className="font-semibold text-white bg-blue-600 py-2 hover:bg-blue-700 active:bg-blue-800 mb-1 text-lg rounded transition-all"
        onClick={() => setOverlay(false)}
      >
        Close
      </button>
    </motion.div>
  );
};

export default VerifyOverlay;
