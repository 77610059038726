import { createContext, useContext, useState } from 'react';
import { AppContext } from '../../partials/AppContext';

export const AdminContext = createContext();

export const AdminContextProvider = ({ children }) => {
  const { loadData, getComp, showSnackBar } = useContext(AppContext);
  const defaultPaper = {
    _id: '',
    title: '',
    year: '',
    doi: '',
    volume: '',
    authors: [],
    keywords: [],
    countries: [],
    abstract: '',
    idx: -1,
  };
  const [requestStatus, setRequestStatus] = useState('none');
  const [paper, setPaper] = useState({ idx: -1 });
  const [searchResult, setSearchResult] = useState([]);
  const [parsedData, setParsedData] = useState([]);
  const [overlay, setOverlay] = useState(false);
  const [editParams, setEditParams] = useState(defaultPaper);

  const variants = {
    success: {
      backgroundColor: ['rgb(0, 255, 0)', '#0074ff'],
    },
    failed: {
      backgroundColor: ['rgb(255, 0, 0)', '#0074ff'],
    },
    none: { opacity: 1 },
  };

  const success = (response) => {
    showSnackBar('Success');
  };

  const addAuthor = (author) => {
    loadData(
      '/insert/author.js',
      { name: author },
      {
        method: 'POST',
      }
    ).then(() => {
      getComp();
      success();
    });
  };

  const addKeyword = (keyword) => {
    loadData(
      '/insert/keyword.js',
      { name: keyword },
      {
        method: 'POST',
      }
    ).then(() => {
      getComp();
      success();
    });
  };

  const addCountry = (country) => {
    loadData(
      '/insert/country.js',
      { name: country },
      {
        method: 'POST',
      }
    ).then(() => {
      getComp();
      success();
    });
  };

  const deletePaper = (id) => {
    loadData(
      '/delete/paper.js',
      { id },
      {
        method: 'POST',
      }
    ).then(success);
  };

  const addPaper = () => {
    const body = { type: 'one', papers: paper };
    loadData('/insert/papers.js', body, {
      method: 'POST',
    }).then(success);
  };

  const editPaper = () => {
    loadData('/update/paper.js', editParams, {
      method: 'POST',
    }).then(success);
  };

  const search = (title) => {
    setSearchResult([]);
    setEditParams(defaultPaper);
    loadData(
      '/get/papers.js',
      { type: 'one', title },
      {
        method: 'POST',
      }
    ).then((response) => {
      setSearchResult(response);
      setEditParams(response);
      setRequestStatus('success');
      success();
    });
  };

  const deleteComp = (item, id) => {
    loadData(
      '/delete/comp.js',
      { item, id },
      {
        method: 'POST',
      }
    ).then(() => {
      getComp();
      setRequestStatus('success');
      success();
    });
  };

  const removeParsedData = (idx) => {
    setParsedData(parsedData.filter((elm, i) => i !== idx));
  };

  const addAllParsedData = () => {
    const body = {
      type: 'many',
      papers: parsedData,
    };
    loadData('/insert/papers.js', body, {
      method: 'POST',
    }).then(success);
  };

  return (
    <AdminContext.Provider
      value={{
        paper,
        requestStatus,
        searchResult,
        parsedData,
        overlay,
        editParams,
        variants,
        defaultPaper,
        addAuthor,
        addKeyword,
        addCountry,
        deletePaper,
        addPaper,
        editPaper,
        search,
        deleteComp,
        setPaper,
        removeParsedData,
        addAllParsedData,
        setSearchResult,
        setOverlay,
        setEditParams,
        setParsedData,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};
