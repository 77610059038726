import { useContext } from 'react';
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import { AppContext } from '../../partials/AppContext';
import { DataContext } from './DataContext';

const Search = () => {
  const { isLoading, prepareOptions } = useContext(AppContext);
  const {
    searchParameters,
    setSortKey,
    setSearchParameters,
    goToPage,
  } = useContext(DataContext);
  function getRndChr() {
    return String.fromCharCode(Math.floor(Math.random() * (122 - 97)) + 97);
  }
  return (
    <div className="flex flex-col px-1">
      <Select
        isMulti={true}
        isClearable={true}
        isSearchable={true}
        options={prepareOptions().countriesOptions}
        placeholder="Region"
        classNamePrefix="select"
        onChange={(e) => {
          if (searchParameters.countries.includes(e)) return;
          setSearchParameters({
            ...searchParameters,
            countries: e ? e : [],
          });
        }}
      />
      <Select
        isMulti={true}
        isClearable={true}
        isSearchable={true}
        options={prepareOptions().keywordsOptions}
        placeholder="Keyword"
        className="my-4"
        classNamePrefix="select"
        onChange={(e) => {
          if (searchParameters.keywords.includes(e)) return;
          setSearchParameters({
            ...searchParameters,
            keywords: e ? e : [],
          });
        }}
      />
      <div className="flex justify-between mb-4">
        <input
          type="number"
          className="text-xs sm:text-lg mr-1 rounded border border-gray-100 shadow md:px-4 p-2 box-border flex-auto"
          placeholder="Year From"
          value={searchParameters.yearFrom}
          onChange={(e) =>
            setSearchParameters({
              ...searchParameters,
              yearFrom: e.target.value.replace(/\D/, ''),
            })
          }
        />
        <input
          type="number"
          className="text-xs sm:text-lg rounded border border-gray-100 shadow md:px-4 p-2 box-border flex-auto"
          placeholder="Year To"
          value={searchParameters.yearTo}
          onChange={(e) =>
            setSearchParameters({
              ...searchParameters,
              yearTo: e.target.value.replace(/\D/, ''),
            })
          }
        />
      </div>

      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => {
          goToPage(0);
          setSortKey('-' + getRndChr());
        }}
        disabled={isLoading}
      >
        {isLoading ? 'Loading' : 'Search'}
      </Button>
    </div>
  );
};

export default Search;
