import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import Select from 'react-select';
import { AdminContext } from './AdminContext';
import { AppContext } from '../../partials/AppContext';

const AdminEdit = () => {
  const { isLoading, prepareOptions } = useContext(AppContext);
  const {
    setEditParams,
    editParams,
    requestStatus,
    variants,
    editPaper,
  } = useContext(AdminContext);

  return (
    <div className="edit hover:shadow transition-shadow rounded border-gray-200 border flex flex-col p-4">
      <div className="adminTitle text-3xl font-bold dark:text-gray-200">
        Edit
      </div>
      <input
        className="p-2 text-lg border border-gray-300 rounded rounded-r-none mb-1 flex-1 border-r-0"
        type="text"
        name="id"
        placeholder="ID"
        value={editParams._id}
        readOnly
      />
      <input
        type="text"
        className="p-2 text-lg border border-gray-300 rounded rounded-r-none mb-1 flex-1 border-r-0"
        name="title"
        placeholder="Title"
        value={editParams.title}
        onChange={(e) =>
          setEditParams({ ...editParams, title: e.target.value })
        }
      />
      <input
        className="p-2 text-lg border border-gray-300 rounded mb-1"
        type="number"
        name="year"
        placeholder="Year"
        value={editParams.year}
        onChange={(e) =>
          setEditParams({
            ...editParams,
            year: e.target.value.replace(/\D/, ''),
          })
        }
      />
      <input
        className="p-2 text-lg border border-gray-300 rounded mb-1"
        type="text"
        name="volume"
        placeholder="Journal"
        value={editParams.volume}
        onChange={(e) =>
          setEditParams({ ...editParams, volume: e.target.value })
        }
      />
      <input
        className="p-2 text-lg border border-gray-300 rounded mb-1"
        type="text"
        name="doi"
        placeholder="DOI"
        value={editParams.doi}
        onChange={(e) => setEditParams({ ...editParams, doi: e.target.value })}
      />
      <Select
        className="mb-1"
        options={prepareOptions().keywordsOptions}
        value={editParams.keywords?.map((keyword) => ({
          label: keyword,
          value: keyword,
        }))}
        isSearchable
        isMulti
        isLoading={isLoading}
        isDisabled={isLoading}
        placeholder="Keywords"
        onChange={(e) => {
          setEditParams({
            ...editParams,
            keywords: e ? e.map((e) => e.value) : [],
          });
        }}
      />
      <Select
        className="mb-1"
        options={prepareOptions().authorsOptions}
        value={editParams.authors?.map((author) => ({
          label: author,
          value: author,
        }))}
        isSearchable
        isMulti
        isLoading={isLoading}
        isDisabled={isLoading}
        placeholder="Authors"
        onChange={(e) => {
          setEditParams({
            ...editParams,
            authors: e ? e.map((e) => e.value) : [],
          });
        }}
      />
      <Select
        className="mb-1"
        options={prepareOptions().countriesOptions}
        value={editParams.countries?.map((country) => ({
          label: country,
          value: country,
        }))}
        isSearchable
        isMulti
        isLoading={isLoading}
        isDisabled={isLoading}
        placeholder="Countries"
        onChange={(e) => {
          setEditParams({
            ...editParams,
            countries: e ? e.map((e) => e.value) : [],
          });
        }}
      />
      <textarea
        type="text"
        className="p-2 text-lg border border-gray-300 rounded mb-1"
        name="abstract"
        placeholder="Abstract"
        value={editParams.abstract}
        onChange={(e) =>
          setEditParams({ ...editParams, abstract: e.target.value })
        }
      />
      <input
        className="p-2 text-lg border border-gray-300 rounded mb-1"
        type="number"
        name="idx"
        placeholder="Index"
        value={editParams.idx}
        onChange={(e) => setEditParams({ ...editParams, idx: e.target.value })}
      />
      <motion.button
        className="font-semibold text-white bg-blue-600 py-2 hover:bg-blue-700 active:bg-blue-800 mb-1 text-lg rounded transition-all"
        animate={requestStatus}
        variants={variants}
        disabled={isLoading || !editParams._id}
        onClick={() => editPaper(editParams._id)}
      >
        {isLoading ? 'Please Wait...' : 'Save'}
      </motion.button>
    </div>
  );
};

export default AdminEdit;
