import { useContext } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import LandingPage from './pages/Landing/LandingPage';
import Header from './partials/Header';
import Footer from './partials/Footer';
import DataView from './pages/Data/Data';
import Login from './pages/Login/Login';
import Admin from './pages/Admin/Admin';
import { AnimatePresence } from 'framer-motion';
import './partials/assets/tw.css';
import { AppContext } from './partials/AppContext';
import { Snackbar } from '@material-ui/core';
import AbstractModal from './partials/AbstractModal';

const Router = () => {
  const location = useLocation();
  const {
    isLoading,
    isLoggedIn,
    snackOpen,
    setSnackOpen,
    snackMessage,
    modal,
  } = useContext(AppContext);

  return (
    <div
      className={`md:container flex flex-col mx-auto md:max-w-none md:px-4 w-screen min-h-screen justify-between dark:bg-gray-800 ${
        isLoading ? 'cursor-wait' : ''
      }`}
    >
      <Header />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location}>
          <Route exact path="/" component={LandingPage} />
          <Route path="/data">
            <DataView />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/admin">
            {isLoggedIn ? <Admin /> : <Redirect to="login" />}
          </Route>
        </Switch>
      </AnimatePresence>
      <Footer />
      <Snackbar
        open={snackOpen}
        autoHideDuration={1500}
        onClose={() => setSnackOpen(false)}
        message={snackMessage}
      />
      <AbstractModal
        open={modal[0].open}
        close={() => modal[1]((prevState) => ({ ...prevState, open: false }))}
      >
        {modal[0].msg}
      </AbstractModal>
    </div>
  );
};

export default Router;
