import { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { AdminContext } from './AdminContext';
import { AppContext } from '../../partials/AppContext';

const AdminImport = () => {
  const { isLoading } = useContext(AppContext);
  const { variants, requestStatus, setParsedData, setOverlay } = useContext(
    AdminContext
  );
  const [rawData, setRawData] = useState('');
  const [isImported, setIsImported] = useState(false);

  const parseData = (data) => {
    // isUrl(url): helper function to check whether doi is url
    // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url#5717133
    const isUrl = (url) => {
      try {
        new URL(url);
        if (
          !url.match(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
          )
        )
          return false;
        return true;
      } catch (e) {
        return false;
      }
    };

    const papersParsed = [];
    // paper index used to keep track of papers,
    // because we have abstracts which are note considered papers
    let paperidx = 0;

    /**
     * for memory's sake
     * traverse input lines
     * bookFlg: flag to indicate whether the paper is a reference to a book.
     * edsFlg: flag to indicate whether the reference contains (eds.)
     *         which need to be removed and re-entered into the text
     *         because they have a '.'
     * edFlg: same as eds flag
     * split paper by '.' and generate paper records.
     * faulty flags potentially faulty papers by checking size of reference array.
     * doi might contain '.' so we re-add them to get the url
     */
    const lines = data.split('\n');
    lines.forEach((line) => {
      let bookFlg = false;
      let edsFlg = false;
      let edFlg = false;

      if (line.includes('eds.')) {
        edsFlg = true;
        line = line.replace('eds.', '\u3221');
      } else if (line.includes('ed.')) {
        edFlg = true;
        line = line.replace('ed.', '\u3222');
      }

      if (line.includes('In:')) {
        bookFlg = true;
      }

      const paper = line.split('.');
      if (paper.length > 1) {
        const authors = paper[0].split(',').map((author) => author.trim());
        const year = paper[1].trim();
        if (isNaN(Number(year))) {
          papersParsed[paperidx - 1].abstract += line
            .replace('\u3221', 'eds.')
            .replace('\u3222', 'ed.');
          return;
        }
        const title = paper[2].trim();
        let volume = '';
        if (bookFlg) {
          volume = paper[3].trim() + '. ' + paper[4].trim();
        } else {
          volume = paper[3].trim();
        }
        let doi = '';
        let countries = [];
        let keywords = [];
        let abstract = '';
        let faulty = false;

        let filterIdx = bookFlg ? 4 : 3;

        const doiTemp = paper
          .filter((_, idx) => idx > filterIdx)
          .join('.')
          .split(' ');

        doi = doiTemp
          .filter((elm) => isUrl(elm))
          .join('')
          .trim();

        if (!doi && (edFlg || edsFlg)) {
          faulty = paper.length !== 6;
        } else if (!doi && !edFlg && !edsFlg) {
          faulty = paper.length !== 5;
        } else if (doi) {
          let tmpLine = line
            .replace(doi, '')
            .replace('eds.', '')
            .replace('ed.', '');

          faulty = tmpLine.split('.').length !== 5;
        }

        const countryKeyword = paper[paper.length - 1].split(' ').slice(1);

        let insertCountryFlg = true;
        countryKeyword.forEach((elm) => {
          let tempElm = elm;
          if (elm.endsWith(',')) tempElm = elm.substring(0, elm.length - 1);
          tempElm = tempElm.trim();
          if (insertCountryFlg) {
            countries.push(tempElm);
          } else {
            keywords.push(tempElm);
          }
          if (!elm.endsWith(',')) {
            insertCountryFlg = false;
          }
        });
        if (edFlg) {
          volume = volume.replace('\u3222', 'ed.');
        } else if (edsFlg) {
          volume = volume.replace('\u3221', 'eds.');
        }
        papersParsed.push({
          title,
          year,
          volume,
          doi,
          authors,
          countries,
          keywords,
          abstract,
          faulty,
          idx: -1,
        });
        paperidx++;
      }
    });
    setParsedData(papersParsed);
    setIsImported(true);
  };

  const verify = () => {
    setOverlay(true);
  };

  const reset = () => {
    setRawData('');
    setParsedData([]);
    setIsImported(false);
    setOverlay(false);
  };

  return (
    <div className="import hover:shadow transition-shadow rounded border-gray-200 border flex flex-col p-4">
      <div className="adminTitle text-3xl font-bold dark:text-gray-200">
        Import
      </div>
      <textarea
        className="resize-none rounded h-72 my-1 border border-gray-200"
        disabled={false}
        value={rawData}
        onChange={(e) => setRawData(e.target.value)}
      />
      <motion.button
        className="font-semibold text-white bg-blue-600 py-2 hover:bg-blue-700 active:bg-blue-800 mb-1 text-lg rounded transition-all"
        animate={requestStatus}
        variants={variants}
        onClick={() => parseData(rawData)}
      >
        {isLoading ? 'Please Wait...' : 'Import'}
      </motion.button>
      <motion.button
        animate={requestStatus}
        variants={variants}
        disabled={!isImported}
        onClick={() => verify()}
        className={
          isImported
            ? 'font-semibold text-white bg-green-600 py-2 hover:bg-green-700 active:bg-green-800 mb-1 text-lg rounded transition-all'
            : 'font-semibold text-white bg-blue-600 py-2 hover:bg-blue-700 active:bg-blue-800 mb-1 text-lg rounded transition-all'
        }
      >
        {isLoading ? 'Please Wait...' : 'Verify'}
      </motion.button>
      <motion.button
        className="font-semibold text-white bg-blue-600 py-2 hover:bg-blue-700 active:bg-blue-800 mb-1 text-lg rounded transition-all"
        animate={requestStatus}
        variants={variants}
        disabled={!isImported}
        onClick={() => reset()}
      >
        {isLoading ? 'Please Wait...' : 'Reset'}
      </motion.button>
    </div>
  );
};
export default AdminImport;
