import { useContext, useState } from 'react';
import { AdminContext } from './AdminContext';

const AdminSearch = () => {
  const [searchText, setSearchText] = useState(null);
  const { search, searchResult, setEditParams, defaultPaper } = useContext(
    AdminContext
  );
  return (
    <div className="hover:shadow transition-shadow rounded border-gray-200 border flex flex-col p-4">
      <div className="adminTitle text-3xl font-bold dark:text-gray-200">
        Search
      </div>
      <input
        type="text"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        className="p-2 text-lg border border-gray-300 rounded rounded-r-none mb-1 flex-1 border-r-0"
      />
      <select
        className="py-2 my-2 border border-gray-400"
        onChange={(e) =>
          setEditParams(
            searchResult.filter((paper) => paper._id === e.target.value)[0] ||
              defaultPaper
          )
        }
      >
        <option value="">Select Paper...</option>
        {searchResult.map((paper) => {
          return (
            <option key={paper._id} value={paper._id}>
              {paper.title}
            </option>
          );
        })}
      </select>
      <button
        className="font-semibold text-white bg-blue-600 py-2 hover:bg-blue-700 active:bg-blue-800 mb-1 text-lg rounded transition-all"
        onClick={() => search(searchText)}
      >
        Search
      </button>
    </div>
  );
};

export default AdminSearch;
