import { useContext } from 'react';
import CreateableSelect from 'react-select/creatable';
import { motion } from 'framer-motion';
import { AdminContext } from './AdminContext';
import { AppContext } from '../../partials/AppContext';

const AdminAdd = () => {
  const { prepareOptions, isLoading } = useContext(AppContext);
  const {
    paper,
    setPaper,
    addKeyword,
    addCountry,
    addAuthor,
    variants,
    addPaper,
    requestStatus,
  } = useContext(AdminContext);

  return (
    <div className="add hover:shadow transition-shadow rounded border-gray-200 border flex flex-col p-4">
      <div className="adminTitle text-3xl font-bold dark:text-gray-200">
        Add
      </div>
      <input
        type="text"
        className="p-2 text-lg border border-gray-300 rounded mb-1"
        name="title"
        placeholder="Title"
        value={paper.title}
        onChange={(e) => {
          setPaper({ ...paper, title: e.target.value });
        }}
      />
      <input
        type="text"
        className="p-2 text-lg border border-gray-300 rounded mb-1"
        name="doi"
        placeholder="DOI"
        value={paper.doi}
        onChange={(e) => {
          setPaper({ ...paper, doi: e.target.value });
        }}
      />
      <input
        type="number"
        className="appearance-none p-2 text-lg border border-gray-300 rounded mb-1"
        name="year"
        placeholder="Year"
        value={paper.year}
        onChange={(e) => {
          setPaper({ ...paper, year: e.target.value.replace(/\D/, '') });
        }}
      />
      <input
        type="text"
        className="p-2 text-lg border border-gray-300 rounded mb-1"
        name="volume"
        placeholder="Journal"
        value={paper.volume}
        onChange={(e) => {
          setPaper({ ...paper, volume: e.target.value });
        }}
      />
      <CreateableSelect
        className="mb-1"
        isMulti
        isSearchable
        onCreateOption={addKeyword}
        isLoading={isLoading}
        isDisabled={isLoading}
        options={prepareOptions().keywordsOptions}
        placeholder="Keywords"
        onChange={(e) => {
          if (paper.keywords?.includes(e)) return;
          setPaper({
            ...paper,
            keywords: e ? e.map((e) => e.value) : [],
          });
        }}
      />
      <CreateableSelect
        className="mb-1"
        isMulti
        isSearchable
        onCreateOption={addCountry}
        isLoading={isLoading}
        isDisabled={isLoading}
        options={prepareOptions().countriesOptions}
        placeholder="Countries"
        onChange={(e) => {
          if (paper.countries?.includes(e)) return;
          setPaper({
            ...paper,
            countries: e ? e.map((e) => e.value) : [],
          });
        }}
      />
      <CreateableSelect
        className="mb-1"
        isMulti
        isSearchable
        onCreateOption={addAuthor}
        isLoading={isLoading}
        isDisabled={isLoading}
        options={prepareOptions().authorsOptions}
        placeholder="Authors"
        onChange={(e) => {
          if (paper.authors?.includes(e)) return;
          setPaper({
            ...paper,
            authors: e ? e.map((e) => e.value) : [],
          });
        }}
      />
      <textarea
        type="text"
        className="p-2 text-lg border border-gray-300 rounded mb-1"
        name="abstract"
        placeholder="Abstract"
        value={paper.abstract}
        onChange={(e) => {
          setPaper({ ...paper, abstract: e.target.value });
        }}
      />
      <input
        type="number"
        className="p-2 text-lg border border-gray-300 rounded mb-1"
        name="idx"
        placeholder="Index"
        value={paper.idx}
        onChange={(e) => {
          setPaper({ ...paper, idx: e.target.value });
        }}
      />
      <motion.button
        animate={requestStatus}
        variants={variants}
        disabled={isLoading}
        onClick={addPaper}
        className="font-semibold text-white bg-blue-600 py-2 hover:bg-blue-700 active:bg-blue-800 mb-1 text-lg rounded transition-all"
      >
        {isLoading ? 'Please Wait...' : 'Add'}
      </motion.button>
    </div>
  );
};

export default AdminAdd;
