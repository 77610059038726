import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from './AppContext';
import promisedLogo from './assets/Logo Promised.jpg';
import peopleInMotionLogo from './assets/People in Motion logo.png';
import RNIF from './assets/logos.png';
import AGLeventis from './assets/AGLeventis_Logo.jpg';
import David_prize from './assets/david_prize.png';

const Footer = () => {
  const { isLoggedIn, logout } = useContext(AppContext);
  return (
    <footer className="py-2 w-full flex flex-col md:flex-row justify-between items-center md:text-xl font-bold text-blue-500 rounded-md text-lg dark:bg-gray-800">
      <div>
        {isLoggedIn ? (
          <Link
            to="/login"
            className="py-2 px-4 rounded hover:bg-gray-100 transition-colors dark:hover:bg-gray-600"
            onClick={logout}
          >
            Logout
          </Link>
        ) : (
          <Link
            to="/login"
            className="py-2 px-4 rounded hover:bg-gray-100 transition-colors dark:hover:bg-gray-600"
          >
            Admin Login
          </Link>
        )}
        {isLoggedIn ? (
          <Link
            to="/admin"
            className="py-2 px-4 rounded hover:bg-gray-100 transition-colors dark:hover:bg-gray-600"
          >
            Dashboard
          </Link>
        ) : null}
      </div>
      <img
        className="h-16 lg:h-24 rounded"
        src={AGLeventis}
        alt="AGLeventisLogo"
      />
      <img
        className="h-16 lg:h-24 rounded"
        src={peopleInMotionLogo}
        alt="peopleInMotionLogo"
      />
      <p className="px-4 py-2">Creative Commons license (CC BY-NC-SA 4.0) </p>
      <img
        className="h-16 lg:h-24 rounded"
        src={promisedLogo}
        alt="promisedLogo"
      />
      <img
        className="h-16 lg:h-24 rounded"
        src={David_prize}
        alt="David_prizeLogo"
      />
      <img className="h-16 lg:h-24 rounded" src={RNIF} alt="RNIF" />
      <Link
        className="text-right py-2 px-4 flex-shrink rounded hover:bg-gray-100 transition-colors dark:hover:bg-gray-600"
        to={{
          pathname: 'https://www.cyi.ac.cy/',
        }}
        target="_blank"
      >
        The Cyprus Institute
      </Link>
    </footer>
  );
};

export default Footer;
