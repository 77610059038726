import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from './AppContext';
import logo from './assets/logo.png';
import darkLogo from './assets/logo_dark.png';
import bibLogo from './assets/BiblioArch logo.png';

const Header = () => {
  const { theme } = useContext(AppContext);
  const { pathname } = useLocation();
  return (
    <header className="flex flex-col md:flex-row items-center justify-between py-6 dark:bg-gray-800">
      <Link to="/">
        <img
          className="h-16 lg:h-24 rounded"
          src={theme ? darkLogo : logo}
          alt="logo"
        />
      </Link>
      <Link to="/">
        <img className="h-16 lg:h-28 rounded" src={bibLogo} alt="bibLogo" />
      </Link>
      <div>
        <a
          className="py-2 px-4 rounded hover:bg-gray-100 active:bg-gray-200 transition-colors dark:hover:bg-gray-600 text-blue-500 text-md md:text-xl font-semibold sm:text-md"
          href="https://docs.google.com/forms/d/e/1FAIpQLScxnNHa_uCS1IC8pDFPFLYocKokHHMNdiwTffR6lmxSshuKvg/viewform?usp=sf_link"
          rel="noreferrer"
          target="_blank"
        >
          Submissions/Feedback
        </a>
        {/* <button
          className="py-2 px-4 rounded hover:bg-gray-100 transition-colors dark:hover:bg-gray-600"
          onClick={() => toggleDark()}
        >
          🌓
        </button> */}
        {pathname === '/' ? null : (
          <Link
            to="/"
            className="py-2 px-4 rounded hover:bg-gray-100 active:bg-gray-200 transition-colors dark:hover:bg-gray-600 text-blue-500 text-md md:text-xl font-semibold sm:text-md"
          >
            {'\u2039'} Back
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;
