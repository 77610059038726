import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DataContext } from './DataContext';
import { AppContext } from '../../partials/AppContext';

import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Paper,
  MenuItem,
  Select,
  ButtonGroup,
} from '@material-ui/core';

const DataTable = () => {
  const {
    isLoading,
    results,
    isInExports,
    addToExports,
    removeFromExports,
    goToPage,
    pageIndex,
    pageSize,
    count,
    resizeTable,
    generatePDF,
    generateTSV,
    addAll,
    clearExports,
    setSortKey,
    sortKey,
  } = useContext(DataContext);

  const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    hasAbstract: { cursor: 'pointer', color: 'blue', fontWeight: 700 },
  });

  const { modal } = useContext(AppContext);
  const setModalState = modal[1];

  const [pageNumber, setPageNumber] = useState(pageIndex + 1);

  useEffect(() => {
    setPageNumber(pageIndex + 1);
  }, [pageIndex]);

  const classes = useStyles();

  const generateTableHeaders = () => {
    return (
      results[0] &&
      Object.keys(results[0]).map((key) => {
        let pointer = '\u25BC';
        let sortableIcon = '\u21C5';
        let clickHandler = () => setSortKey(key);
        let className = 'cursor-pointer';
        const nonDisplayables = ['_id', 'abstract', 'faulty', 'idx'];
        const nonSortables = ['keywords', 'countries', 'doi', 'authors'];
        if (nonSortables.includes(key)) {
          clickHandler = null;
          className = '';
          pointer = '';
          sortableIcon = '';
        }

        if (key === 'volume')
          return (
            <TableCell key={key} className={className} onClick={clickHandler}>
              JOURNAL/BOOK{sortableIcon}
              {sortKey === key ? pointer : ''}
            </TableCell>
          );
        if (key === 'keywords')
          return (
            <TableCell key={key} className={className} onClick={clickHandler}>
              KEYWORD
              {sortKey === key ? pointer : ''}
            </TableCell>
          );
        if (key === 'countries')
          return (
            <TableCell key={key} className={className} onClick={clickHandler}>
              REGION
            </TableCell>
          );
        if (!nonDisplayables.includes(key)) {
          return (
            <TableCell key={key} className={className} onClick={clickHandler}>
              {key.toUpperCase() +
                sortableIcon +
                (sortKey === key ? pointer : '')}
            </TableCell>
          );
        }
        return null;
      })
    );
  };

  return (
    <>
      {results[0] && (
        <Paper className={classes.root}>
          <TableContainer
            elevation={0}
            component={Paper}
            className={classes.container}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {generateTableHeaders()}
                  <TableCell>EXPORT</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((row) => {
                  return (
                    <TableRow key={row._id}>
                      <TableCell
                        className={row.abstract ? classes.hasAbstract : null}
                        onClick={() =>
                          setModalState({ msg: row.abstract, open: true })
                        }
                      >
                        {row.title}
                      </TableCell>
                      <TableCell>{row.year}</TableCell>
                      <TableCell>{row.volume}</TableCell>
                      <TableCell>{<a href={row.doi}>{row.doi}</a>}</TableCell>
                      <TableCell>{row.authors.join(', ')}</TableCell>
                      <TableCell>{row.countries.join(', ')}</TableCell>
                      <TableCell>{row.keywords.join(', ')}</TableCell>
                      <TableCell>
                        <Button
                          variant={isInExports(row) ? 'outlined' : 'contained'}
                          color="primary"
                          onClick={() => addToExports(row)}
                          disabled={isInExports(row)}
                        >
                          +
                        </Button>
                        <Button
                          variant={!isInExports(row) ? 'outlined' : 'contained'}
                          color="secondary"
                          onClick={() => removeFromExports(row)}
                          disabled={!isInExports(row)}
                        >
                          x
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="controls flex flex-col justify-between items-center sm:flex-row">
            <div>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => goToPage(0)}
                disabled={pageIndex <= 0 || isLoading}
              >
                {'<<'}
              </Button>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => goToPage(pageIndex - 1)}
                disabled={pageIndex <= 0 || isLoading}
              >
                {'<'}
              </Button>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => goToPage(pageIndex + 1)}
                disabled={pageIndex >= count - 1 || isLoading}
              >
                {'>'}
              </Button>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => goToPage(count - 1)}
                disabled={pageIndex >= count - 1 || isLoading}
              >
                {'>>'}
              </Button>
            </div>
            <div className="pageCount">
              Page{' '}
              <input
                className="w-10 border border-gray-400"
                type="text"
                onChange={(e) => setPageNumber(Number(e.target.value))}
                onBlur={() => goToPage(Number(pageNumber) - 1)}
                value={pageNumber}
                disabled={isLoading}
              />{' '}
              of {count}
            </div>
            <div>
              {'Entries per page: '}
              <Select
                onChange={(e) => resizeTable(e)}
                size="large"
                color="primary"
                defaultValue={pageSize}
                value={pageSize}
                disabled={isLoading}
              >
                {[10, 20, 30, 40, 50, 100, 500, 1000].map((option, i) => {
                  return (
                    <MenuItem key={i} size="large" value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <Button
              onClick={generatePDF}
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
            >
              Download PDF
            </Button>
            <Button
              onClick={generateTSV}
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
            >
              Download TSV
            </Button>
            <ButtonGroup>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={addAll}
                disabled={isLoading}
              >
                Add All
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={clearExports}
              >
                Clear All
              </Button>
            </ButtonGroup>
          </div>
        </Paper>
      )}
    </>
  );
};

export default DataTable;
