import { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { AppContext } from '../../partials/AppContext';

const Login = () => {
  const [password, setPassword] = useState('');

  const { login, isLoggedIn } = useContext(AppContext);

  const variants = {
    loggedIn: { backgroundColor: 'rgba(61, 226, 28, 0.5)' },
    error: {
      backgroundColor: ['rgba(230, 12, 28, 0.5)', 'rgba(230, 12, 28, 0.0)'],
    },
    none: { opacity: 1 },
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: 'spring', stiffness: 260, damping: 20 }}
      className="login flex flex-1 items-center justify-center rounded dark:bg-gray-800"
    >
      <motion.div className="flex flex-nowrap" variants={variants} layout>
        <input
          type="password"
          name="pass"
          id="pass"
          placeholder="Secret"
          className="px-4 py-2 md:px-8 md:py-4 text-md md:text-2xl border border-r-0 border-gray-300 rounded-l hover:shadow transition-shadow"
          value={isLoggedIn ? '' : password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={isLoggedIn}
        />
        <motion.button
          className="appearance-none focus:outline-none focus:bg-blue-200 px-4 py-2 md:px-8 md:py-4 font-bold bg-gray-100 text-md md:text-2xl active:bg-blue-700 border border-l-0 border-gray-300 rounded-r hover:shadow hover:text-white hover:bg-blue-600 transition-all"
          onClick={(e) => login(password)}
          disabled={isLoggedIn}
        >
          {isLoggedIn ? 'Logged In' : 'Login'}
        </motion.button>
      </motion.div>
    </motion.div>
  );
};

export default Login;
