import { motion } from 'framer-motion';
import AdminAdd from './AdminAdd';
import AdminDelete from './AdminDelete';
import AdminEdit from './AdminEdit';
import AdminImport from './AdminImport';
import VerifyOverlay from './VerifyOverlay';
import { AdminContextProvider } from './AdminContext';
import AdminSearch from './AdminSearch';

const Admin = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: 'spring', stiffness: 260, damping: 20 }}
      className="admin flex-1 mx-1 sm:mx-0"
    >
      <AdminContextProvider>
        <AdminSearch />
        <AdminAdd />
        <AdminDelete />
        <AdminEdit />
        <AdminImport />
        <VerifyOverlay />
      </AdminContextProvider>
    </motion.div>
  );
};
export default Admin;
