import { useState, createContext, useEffect } from 'react';

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  let uri = 'https://papers-api.vercel.app/api';

  if (document.location.hostname === 'localhost') {
    uri = 'http://localhost:3000/api';
  }

  useEffect(() => {
    // redirect to https
    if (
      !document.location.host.includes('localhost') &&
      !document.location.protocol.includes('https')
    ) {
      document.location = 'https://' + document.location.host;
    }
    if (document.location.host.includes('.glitch.')) {
      document.location = 'https://www.biblioarch.com/';
    }
    refresh(false);
    getComp();
    checkTheme();
    // eslint-disable-next-line
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [countries, setCountries] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [theme, setTheme] = useState(false);
  const modal = useState({
    msg: '',
    open: false,
  });
  let timeoutHandle = -1;

  const prepareOptions = () => {
    let countriesOptions = countries
      .map(({ name }) => ({
        label: name,
        value: name,
      }))
      .sort((a, b) => {
        return a.label > b.label ? 1 : -1;
      });
    let keywordsOptions = keywords
      .map(({ keyword }) => ({
        label: keyword,
        value: keyword,
      }))
      .sort((a, b) => {
        return a.label > b.label ? 1 : -1;
      });
    let authorsOptions = authors
      .map(({ name }) => ({
        label: name,
        value: name,
      }))
      .sort((a, b) => {
        return a.label > b.label ? 1 : -1;
      });
    return { countriesOptions, keywordsOptions, authorsOptions };
  };

  const refresh = (showError = true) => {
    loadData('/auth/refresh', {}, { method: 'POST' }, true)
      .then((response) => {
        setToken(response.accessToken);
        timeoutHandle = setTimeout(refresh, 29 * 60 * 1000);
        setIsLoggedIn(true);
      })
      .catch(() => {
        clearTimeout(timeoutHandle);
        if (showError) showSnackBar('Unauthorized! Logged Out!');
        setIsLoggedIn(false);
        setToken(null);
      });
  };

  const login = (password) => {
    loadData('/auth/login', { password }, { method: 'POST' }, true)
      .then((response) => {
        const { accessToken } = response;
        clearTimeout(timeoutHandle);
        timeoutHandle = setTimeout(refresh, 29 * 60 * 1000);
        setToken(accessToken);
        setIsLoggedIn(true);
      })
      .catch((e) => showSnackBar('Unauthorized'));
  };
  const logout = () => {
    loadData('/auth/logout', {}, { method: 'POST' });
    clearTimeout(timeoutHandle);
    setToken(null);
    setIsLoggedIn(false);
  };

  const loadData = (
    endpoint,
    body,
    options,
    suppressAndPropagateError = false
  ) => {
    setIsLoading(true);
    return new Promise((resolve, reject) => {
      setSnackMessage('Loading');
      setSnackOpen(true);
      fetch(uri + endpoint, {
        ...options,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (!(res.status === 200 || res.status === 304))
            throw new Error('Network Error');
          return res;
        })
        .then((res) => {
          setIsLoading(false);
          resolve(res.json());
        })
        .catch((err) => {
          setIsLoading(false);
          if (suppressAndPropagateError) {
            reject();
            return;
          }
          showSnackBar(err.message);
        });
    });
  };

  const getComp = () => {
    loadData('/get/comp.js').then((response) => {
      setKeywords(response.keywords);
      setCountries(response.countries);
      setAuthors(response.authors);
    });
  };

  const showSnackBar = (message) => {
    setSnackOpen(false);
    setSnackMessage(message);
    setSnackOpen(true);
  };

  const checkTheme = () => {
    const html = document.querySelector('html');
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) html.classList = storedTheme === 'dark' ? 'dark' : 'light';
    // setTheme(storedTheme === 'dark');
    setTheme(false);
  };

  const toggleDark = () => {
    const storedTheme = localStorage.getItem('theme');
    const html = document.querySelector('html');
    if (storedTheme) {
      html.classList = storedTheme === 'dark' ? 'light' : 'dark';
      setTheme(!(storedTheme === 'dark'));
    }
    localStorage.setItem('theme', storedTheme === 'dark' ? 'light' : 'dark');
  };

  return (
    <AppContext.Provider
      value={{
        keywords,
        authors,
        countries,
        isLoading,
        isLoggedIn,
        snackOpen,
        snackMessage,
        theme,
        loadData,
        getComp,
        login,
        logout,
        setSnackOpen,
        prepareOptions,
        showSnackBar,
        toggleDark,
        setIsLoading,
        modal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
